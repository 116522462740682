import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

const session = {
  get worldId() {
    return window.sessionStorage.getItem("alpacaWorldId");
  },
  set worldId(value) {
    return window.sessionStorage.setItem("alpacaWorldId", value);
  },
};

// 永続化したい値はここに記述
const StateData = {
  userId: "",
  isPremiumUser: false,
  premiumToDate: null,
  publicUserId: "",
  baseTextUrlCardDisplayFlg: false,
  userIdToken: "",
  _characterIds: {},
  _characterInfos: {},
  _tokenLists: {},
  communityCategories: [],
  _communityCategoryLists: {},
  _tmpCharacterId: null,
  _tmpCharacterInfo: null,
  _tmpTokenList: null,
  _tmpCommunityCategoryList: null,
  creatorId: "",
  statusChange: "0",
  masterId: "",
  mailAddress: "",

  adminFlag: "0",
  playerFlag: "0",
  masterFlag: "0",
  creatorFlag: "0",
  userName: "",
  userIcon: "",
  cLikedList: [],
  readyMadeLikedList: [],

  userClassName: "",
  userClassIcon: "",
  masterClassName: "",
  masterClassIcon: "",
  illustClassName: "",
  illustClassIcon: "",
  illustClassId: "",
  voiceClassName: "",
  voiceClassIcon: "",
  voiceClassId: "",
  writerClassName: "",
  writerClassIcon: "",
  writerClassId: "",
  adminClassName: "",
  adminClassIcon: "",

  lastUsedClass: "",
  updateTimeStamp: "",
  parameterInfo: {
    worldId: {
      parameterCalcBetweenGroupList: [],
      parameterCalcInnerGroupList: [],
      worldItemUseMaster: {},
      worldItemNameMaster: {},
      worldInfoFlgs: {},
      parameterNameList: [{}],
    }
  },
  worldTokenInfoList: {},
  lastUpdated: "",
  communityThreadListForNotification: [],
};

class AlpacaStore {
  constructor(storageKey, storage) {
    this.key = storageKey;
    this.storage = storage;
    this.load();

    window.addEventListener("storage", (e) => {
      if (e.key === this.key || "" === this.key) {
        this.load();
      }
    });
  }

  get worldId() {
    return session.worldId;
  }

  set worldId(value) {
    if ((!session.worldId || "" === session.worldId) && value) {
      if (null !== this._tmpCharacterId) {
        Vue.set(this.characterIds, value, this._tmpCharacterId);
        this._tmpCharacterId = null;
        this.save();
      }
      if (null !== this._tmpCharacterInfo) {
        Vue.set(this.characterInfos, value, this._tmpCharacterInfo);
        this._tmpCharacterInfo = null;
        this.save();
      }
      if (null !== this._tmpTokenList) {
        Vue.set(this.tokenLists, value, this._tmpTokenList);
        this._tmpTokenList = null;
        this.save();
      }
      if (null !== this._tmpCommunityCategoryList) {
        Vue.set(
          this.communityCategoryLists,
          value,
          this._tmpCommunityCategoryList
        );
        this._tmpCommunityCategoryList = null;
        this.save();
      }
    }
    session.worldId = value;
  }

  get characterIds() {
    if (!(this._characterIds instanceof Object))
      Vue.set(this, "_characterIds", {});
    return this._characterIds;
  }

  get characterId() {
    if (session.worldId && this.characterIds[session.worldId]) {
      return this.characterIds[session.worldId];
    } else {
      return this._tmpCharacterId;
    }
  }

  set characterId(value) {
    if (!session.worldId && !value) return;
    if (!session.worldId) {
      this._tmpCharacterId = value; // 仮設定
    } else {
      Vue.set(this.characterIds, session.worldId, value);
    }
    this.save();
  }

  get characterInfos() {
    if (!(this._characterInfos instanceof Object))
      Vue.set(this, "_characterInfos", {});
    return this._characterInfos;
  }

  get characterInfo() {
    return session.worldId
      ? this.characterInfos[session.worldId]
      : this._tmpCharacterInfo;
  }

  set characterInfo(value) {
    if (!session.worldId && !value) return;
    if (!session.worldId) {
      this._tmpCharacterInfo = value; // 仮設定
    } else {
      Vue.set(this.characterInfos, session.worldId, value);
    }
    this.save();
  }

  get tokenLists() {
    if (!(this._tokenLists instanceof Object)) Vue.set(this, "_tokenLists", {});
    return this._tokenLists;
  }

  get tokenList() {
    return session.worldId
      ? this.tokenLists[session.worldId]
      : this._tmpTokenList;
  }

  set tokenList(value) {
    if (!session.worldId && !value) return;
    if (!session.worldId) {
      this._tmpTokenList = value; // 仮設定
    } else {
      Vue.set(this.tokenLists, session.worldId, value);
    }
    this.save();
  }

  get communityCategoryLists() {
    if (!(this._communityCategoryLists instanceof Object))
      Vue.set(this, "_communityCategoryLists", {});
    return this._communityCategoryLists;
  }

  get communityCategoryList() {
    return session.worldId
      ? this.communityCategoryLists[session.worldId]
      : this._tmpCommunityCategoryList;
  }

  set communityCategoryList(value) {
    if (!session.worldId && !value) return;
    if (!session.worldId) {
      this._tmpCommunityCategoryList = value; // 仮設定
    } else {
      Vue.set(this.communityCategoryLists, session.worldId, value);
    }
    this.save();
  }

  init() {
    Object.assign(this, StateData);
  }

  load() {
    try {
      const obj = JSON.parse(this.storage.getItem(this.key));
      if (obj) {
        for (let key in StateData) {
          if (key in obj) {
            this[key] = obj[key];
          }
        }
      }
    } catch (e) {
    }
  }

  save() {
    const obj = Object.create(null);
    for (let key in StateData) {
      obj[key] = this[key];
    }
    this.storage.setItem(this.key, JSON.stringify(obj));
  }

  logout() {
    this.init();
  }
}

window.localStorage.removeItem("alpacaState");

export default new Vuex.Store({
  state: new AlpacaStore("alpacaStore", window.localStorage),
  mutations: {
    saveUserId(state, payload) {
      state.userId = payload.userId;
    },
    saveIsPremiumUser(state, payload) {
      state.isPremiumUser = payload.isPremiumUser;
    },
    savePremiumToDate(state, payload) {
      state.premiumToDate = payload.premiumToDate;
    },
    savePublicUserId(state, payload) {
      state.publicUserId = payload.publicUserId;
    },
    saveBaseTextUrlCardDisplayFlg(state, payload) {
      state.baseTextUrlCardDisplayFlg = payload.baseTextUrlCardDisplayFlg;
    },
    saveUserName(state, payload) {
      state.userName = payload.userName;
    },
    saveUserIcon(state, payload) {
      state.userIcon = payload.userIcon;
    },
    saveWorldInfo(state, worldInfo) {
      state.worldId = worldInfo.list;
    },
    saveCharacterId(state, payload) {
      state.characterId = payload;
    },
    saveCharacterInfo(state, payload) {
      state.characterInfo = payload.characterInfo;
    },
    saveTokenList(state, payload) {
      state.tokenList = payload.tokenList;
    },
    saveCommunityCategoryList(state, payload) {
      state.communityCategoryList = payload.communityCategoryList;
    },
    saveCreatorId(state, payload) {
      state.creatorId = payload.creatorId;
    },
    saveStatusChange(state, payload) {
      state.statusChange = payload.statusChange;
    },
    saveMasterId(state, payload) {
      state.masterId = payload.masterId;
    },
    saveAdminFlag(state, payload) {
      state.adminFlag = payload.adminFlag;
    },
    savePlayerFlag(state, payload) {
      state.playerFlag = payload.playerFlag;
    },
    saveMasterFlag(state, payload) {
      state.masterFlag = payload.masterFlag;
    },
    saveCreatorFlag(state, payload) {
      state.creatorFlag = payload.creatorFlag;
    },
    loginUser(state, firebaseUser) {
      state.userId = firebaseUser.userId;
      state.userIdToken = firebaseUser.userIdToken;
    },
    logoutUser(state) {
      state.logout();
    },
    saveUserClassName(state, payload) {
      state.userClassName = payload.userClassName;
    },
    saveUserClassIcon(state, payload) {
      state.userClassIcon = payload.userClassIcon;
    },
    saveMasterClassName(state, payload) {
      state.masterClassName = payload.masterClassName;
    },
    saveMasterClassIcon(state, payload) {
      state.masterClassIcon = payload.masterClassIcon;
    },
    saveIllustClassName(state, payload) {
      state.illustClassName = payload.illustClassName;
    },
    saveIllustClassIcon(state, payload) {
      state.illustClassIcon = payload.illustClassIcon;
    },
    saveIllustClassId(state, payload) {
      state.illustClassId = payload.illustClassId;
    },
    saveVoiceClassName(state, payload) {
      state.voiceClassName = payload.voiceClassName;
    },
    saveVoiceClassIcon(state, payload) {
      state.voiceClassIcon = payload.voiceClassIcon;
    },
    saveVoiceClassId(state, payload) {
      state.voiceClassId = payload.voiceClassId;
    },
    saveWriterClassName(state, payload) {
      state.writerClassName = payload.writerClassName;
    },
    saveWriterClassIcon(state, payload) {
      state.writerClassIcon = payload.writerClassIcon;
    },
    saveWriterClassId(state, payload) {
      state.writerClassId = payload.writerClassId;
    },
    saveAdminClassName(state, payload) {
      state.adminClassName = payload.adminClassName;
    },
    saveAdminClassIcon(state, payload) {
      state.adminClassIcon = payload.adminClassIcon;
    },
    saveLastUsedClass(state, payload) {
      state.lastUsedClass = payload.lastUsedClass;
    },
    saveUpdateTimeStamp(state, payload) {
      state.updateTimeStamp = payload.updateTimeStamp;
    },
    saveMailAddress(state, payload) {
      state.mailAddress = payload.mailAddress;
    },
    //ログインユーザーのお気に入りリストを保存
    cLikedList(state, payload) {
      state.cLikedList = payload.cLikedList;
    },
    readyMadeLikedList(state, payload) {
      state.readyMadeLikedList = payload.readyMadeLikedList;
    },
    parameterInfo(state, payload) {
      state.parameterInfo = payload;
    },
    lastUpdated(state, payload) {
      state.lastUpdated = payload;
    },
    communityThreadListForNotification(state, payload) {
      state.communityThreadListForNotification = payload;
    },
    tailorGalleryLimitationInfo(state, payload) {
      state.tailorGalleryLimitationInfo = payload;
    },
    //ログインユーザーのお気に入りリストの追加と解除
    updateCLiked(state, payload) {
      if (payload.add) {
        const i = state.cLikedList.indexOf(payload.creatorId);
        state.cLikedList.splice(i, 1);
      } else {
        state.cLikedList.push(payload.creatorId);
      }
    },

  },
  actions: {
    userId: function ({commit}, payload) {
      commit("saveUserId", payload);
    },
    publicUserId: function ({commit}, payload) {
      commit("savePublicUserId", payload);
    },
    isPremiumUser: function ({commit}, payload) {
      commit("saveIsPremiumUser", payload);
    },
    premiumToDate: function ({commit}, payload) {
      commit("savePremiumToDate", payload);
    },
    baseTextUrlCardDisplayFlg: function ({commit}, payload) {
      commit("saveBaseTextUrlCardDisplayFlg", payload);
    },
    userName: function ({commit}, payload) {
      commit("saveUserName", payload);
    },
    userIcon: function ({commit}, payload) {
      commit("saveUserIcon", payload);
    },
    worldId: function ({commit}, worldId) {
      commit("saveWorldInfo", {
        list: worldId,
      });
    },
    characterId: function ({commit}, payload) {
      commit("saveCharacterId", payload);
    },
    characterInfo: function ({commit}, payload) {
      commit("saveCharacterInfo", payload);
    },
    tokenList: function ({commit}, payload) {
      commit("saveTokenList", payload);
    },
    communityCategoryList: function ({commit}, payload) {
      commit("saveCommunityCategoryList", payload);
    },
    creatorId: function ({commit}, payload) {
      commit("saveCreatorId", payload);
    },
    statusChange: function ({commit}, payload) {
      commit("saveStatusChange", payload);
    },
    masterId: function ({commit}, payload) {
      commit("saveMasterId", payload);
    },
    adminFlag: function ({commit}, payload) {
      commit("saveAdminFlag", payload);
    },
    playerFlag: function ({commit}, payload) {
      commit("savePlayerFlag", payload);
    },
    masterFlag: function ({commit}, payload) {
      commit("saveMasterFlag", payload);
    },
    creatorFlag: function ({commit}, payload) {
      commit("saveCreatorFlag", payload);
    },
    loginUser: function ({commit}, firebaseUser) {
      commit("loginUser", firebaseUser);
    },
    logoutUser: function ({commit}) {
      commit("logoutUser");
    },
    userClassName: function ({commit}, payload) {
      commit("saveUserClassName", payload);
    },
    userClassIcon: function ({commit}, payload) {
      commit("saveUserClassIcon", payload);
    },

    mailAddress: function ({commit}, payload) {
      commit("saveMailAddress", payload);
    },
    masterClassName: function ({commit}, payload) {
      commit("saveMasterClassName", payload);
    },
    masterClassIcon: function ({commit}, payload) {
      commit("saveMasterClassIcon", payload);
    },

    illustClassName: function ({commit}, payload) {
      commit("saveIllustClassName", payload);
    },
    illustClassIcon: function ({commit}, payload) {
      commit("saveIllustClassIcon", payload);
    },
    illustClassId: function ({commit}, payload) {
      commit("saveIllustClassId", payload);
    },
    voiceClassName: function ({commit}, payload) {
      commit("saveVoiceClassName", payload);
    },
    voiceClassIcon: function ({commit}, payload) {
      commit("saveVoiceClassIcon", payload);
    },
    voiceClassId: function ({commit}, payload) {
      commit("saveVoiceClassId", payload);
    },
    writerClassName: function ({commit}, payload) {
      commit("saveWriterClassName", payload);
    },
    writerClassIcon: function ({commit}, payload) {
      commit("saveWriterClassIcon", payload);
    },
    writerClassId: function ({commit}, payload) {
      commit("saveWriterClassId", payload);
    },
    adminClassName: function ({commit}, payload) {
      commit("saveAdminClassName", payload);
    },
    adminClassIcon: function ({commit}, payload) {
      commit("saveAdminClassIcon", payload);
    },
    lastUsedClass: function ({commit}, payload) {
      commit("saveLastUsedClass", payload);
    },
    updateTimeStamp: function ({commit}, payload) {
      commit("saveUpdateTimeStamp", payload);
    },
    cLikedList: function ({commit}, payload) {
      commit("cLikedList", payload);
    },
    readyMadeLikedList: function ({commit}, payload) {
      commit("readyMadeLikedList", payload);
    },

    parameterInfo: function ({commit}, payload) {
      commit("parameterInfo", payload);
    },
    communityThreadListForNotification: function ({commit}, payload) {
      commit("communityThreadListForNotification", payload);
    },
    async fetchLastUpdated({commit}) {
      const response = await fetch('/version.json');
      const data = await response.json();
      commit('lastUpdated', data.lastUpdated);
    },
    worldTokenInfoList: function ({commit}, payload) {
      commit("worldTokenInfoList", payload);
    },
    updateCLiked: function ({commit}, payload) {
      commit("updateCLiked", payload);
    },
    convertFlg: function ({commit}, payload) {
      commit("saveConvertFlg", payload);
    }
  },
  plugins: [
    function (store) {
      store.state.load();
      store.subscribe((mutation, state) => state.save());
    },
  ],
});
